import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[0]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[10]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[15]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[33]}")` }}
        ></figure>
        <div className="z-[9] relative w-4/5 mx-auto md:h-[140vh] h-[190vh]">
          <div className="w-full h-full pb-[5%] md:pb-[10%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[7].slogan}
            </h1>
            <p className="text-white text-center"> {rpdata?.dbSlogan?.[8].slogan}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
            <div className="flex flex-wrap justify-center items-center pt-5">
              <img className="w-[350px]" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2FA%C3%B1os%20de%20experiencia%2F15_years_experience.png?alt=media&token=921034ef-6781-4a6d-8be0-7e4b0264087a" alt="google"/>
              <img className="w-[250px]" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsello22%20google.png?alt=media&token=fd79370f-b2ac-414f-a4ce-d538c6ad19d5" alt="experience"/>
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
